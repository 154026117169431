/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BlockedIp } from '../models/BlockedIp';
import type { Pagination } from '../models/Pagination';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class BlockedIpService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get all blocked ips
     * Get a list of all blocked ip-addresses
     *
     * - Allowed roles: admin
     * @returns any Success
     * @throws ApiError
     */
    public listBlockedIp({
        search,
        page = 1,
        perPage = 20,
        cache,
    }: {
        /**
         * Search for an ip
         */
        search?: string,
        /**
         * Current page
         */
        page?: number,
        /**
         * Amount of items per page
         */
        perPage?: number,
        cache?: boolean,
    }): CancelablePromise<{
        pagination?: Pagination;
        data?: Array<BlockedIp>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/blocked-ip',
            query: {
                'search': search,
                'page': page,
                'perPage': perPage,
                'cache': cache,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Create blocked ip
     * Create a new blocked ip address
     *
     * - Allowed roles: admin
     * @returns BlockedIp Created
     * @throws ApiError
     */
    public createBlockedIp({
        requestBody,
    }: {
        requestBody: BlockedIp,
    }): CancelablePromise<BlockedIp> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/blocked-ip',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Get blocked ip
     * Fetch a specific IP
     * @returns BlockedIp Success
     * @throws ApiError
     */
    public checkBlockedIp({
        ip,
    }: {
        ip: string,
    }): CancelablePromise<BlockedIp> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/blocked-ip/{ip}',
            path: {
                'ip': ip,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }
    /**
     * Delete blocked ip
     * Delete a blocked ip address
     *
     * - Allowed roles: admin
     * @returns void
     * @throws ApiError
     */
    public deleteBlockedIp({
        ip,
    }: {
        ip: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/blocked-ip/{ip}',
            path: {
                'ip': ip,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
}
