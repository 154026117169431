/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdsTxt } from '../models/AdsTxt';
import type { AdsTxtActionUpdate } from '../models/AdsTxtActionUpdate';
import type { PlatformID } from '../models/PlatformID';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AdsTxtService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get /ads.txt file.
     * Get the contents of the /ads.txt file for a certain platform.
     * @returns AdsTxt Success
     * @throws ApiError
     */
    public getAdsTxtByPlatform({
        platform,
        cache,
    }: {
        platform: PlatformID,
        cache?: boolean,
    }): CancelablePromise<AdsTxt> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/platform/{platform}/ads-txt',
            path: {
                'platform': platform,
            },
            query: {
                'cache': cache,
            },
        });
    }
    /**
     * Create or update /ads.txt file.
     * Create or update the contents of the /ads.txt file for a certain platform.
     *
     * - Allowed roles: editor, admin
     * @returns AdsTxt Success
     * @throws ApiError
     */
    public upsertAdsTxtByPlatform({
        platform,
        requestBody,
    }: {
        platform: PlatformID,
        requestBody: AdsTxtActionUpdate,
    }): CancelablePromise<AdsTxt> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/platform/{platform}/ads-txt',
            path: {
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
}
