/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LocaleID } from '../models/LocaleID';
import type { OddsDossier } from '../models/OddsDossier';
import type { OddsDossierActionUpsert } from '../models/OddsDossierActionUpsert';
import type { PlatformID } from '../models/PlatformID';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class OddsDossierService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get odds dossier data
     * Get odds dossier data for a platform and a locale
     * @returns OddsDossier Success
     * @throws ApiError
     */
    public getOddsDossierByPlatformLocale({
        platform,
        locale,
        cache,
    }: {
        platform: PlatformID,
        /**
         * will return odds dossier data for a specific locale
         */
        locale: LocaleID,
        cache?: boolean,
    }): CancelablePromise<OddsDossier> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/platform/{platform}/locale/{locale}/odds-dossier',
            path: {
                'platform': platform,
                'locale': locale,
            },
            query: {
                'cache': cache,
            },
        });
    }
    /**
     * Create or update the odds dossier data
     * If an odds dossier already exists for the given platform and locale, the API updates the existing one. If it doesn't already exists a new one will be created. Fallback url's and fallback title from news are not set in returned object.
     *
     * - Allowed roles: moderator, editor, admin
     * @returns OddsDossier Success
     * @throws ApiError
     */
    public upsertOddsDossierByPlatformLocale({
        platform,
        locale,
        requestBody,
    }: {
        platform: PlatformID,
        /**
         * Specify which locale this odds dossier belongs to
         */
        locale: LocaleID,
        requestBody: OddsDossierActionUpsert,
    }): CancelablePromise<OddsDossier> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/platform/{platform}/locale/{locale}/odds-dossier',
            path: {
                'platform': platform,
                'locale': locale,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
}
