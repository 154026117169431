import { FC, useCallback, useEffect, useState } from 'react';

import { EventHandler } from '@common/utils/EventHandler';

import { FlashMessage, Props as FlashMessageProps, PublicProps } from './FlashMessage';

import styles from './FlashMessage.module.scss';

export interface Props {
    flashMessageList?: FlashMessageProps[];
}

export const FlashMessageHolder: FC<Props> = (props) => {
    const [flashMessageList, setFlashMessageList] = useState<FlashMessageProps[]>(
        props.flashMessageList || [],
    );

    const handleRemoveMessage = useCallback((id: number) => {
        setFlashMessageList((flashMessageList) => flashMessageList.filter((n) => n.id !== id));
    }, []);

    const triggered = (flashMessageDetails: PublicProps) => {
        const lastItem = flashMessageList[flashMessageList.length - 1];
        setFlashMessageList(
            flashMessageList.concat([
                {
                    id: lastItem ? lastItem.id + 1 : 1,
                    type: flashMessageDetails.type,
                    title: flashMessageDetails.title || '',
                    message: flashMessageDetails.message || '',
                    delayClose: flashMessageDetails.delayClose || 3000,
                    isClosable: flashMessageDetails.isClosable ?? true,
                    icon: flashMessageDetails.icon,
                    removeMessage: handleRemoveMessage,
                },
            ]),
        );
    };

    useEffect(() => {
        const data = localStorage.getItem('flash-message-list');
        if (data && !props.flashMessageList) {
            const _List = JSON.parse(data);
            _List.map(
                (flashMessageItem: FlashMessageProps) =>
                    (flashMessageItem.removeMessage = handleRemoveMessage),
            );
            setFlashMessageList(_List);
        }
    }, [handleRemoveMessage, props.flashMessageList]);

    useEffect(() => {
        localStorage.setItem('flash-message-list', JSON.stringify(flashMessageList));
    }, [flashMessageList]);

    useEffect(() => {
        EventHandler.bind('FlashMessage::create', null, triggered.bind(this));
        return () => {
            EventHandler.unbind('FlashMessage::create', true);
        };
    });

    const classNames = ['FlashMessageHolder', styles.FlashMessageHolder];

    return (
        <ul className={classNames.join(' ')}>
            {(props.flashMessageList || flashMessageList).map((flashMessageItem) => (
                <FlashMessage key={flashMessageItem.id} {...flashMessageItem} />
            ))}
        </ul>
    );
};
