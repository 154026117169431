/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FilteredComment } from '../models/FilteredComment';
import type { Pagination } from '../models/Pagination';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class FilteredCommentService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get filteredComments
     * Return a list of filtered comments for the current context.
     *
     * - Allowed roles: moderator, editor, admin
     * @returns any Success
     * @throws ApiError
     */
    public listFilteredCommentByDomainId({
        contextId,
        page = 1,
        perPage = 25,
        search,
        cache,
    }: {
        contextId: number,
        /**
         * Current page
         */
        page?: number,
        /**
         * Amount of items per page
         */
        perPage?: number,
        search?: string,
        cache?: boolean,
    }): CancelablePromise<{
        pagination?: Pagination;
        data?: Array<FilteredComment>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/filtered-comment',
            path: {
                'contextID': contextId,
            },
            query: {
                'page': page,
                'perPage': perPage,
                'search': search,
                'cache': cache,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
}
