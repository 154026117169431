/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Page } from '../models/Page';
import type { PageActionCreate } from '../models/PageActionCreate';
import type { Pagination } from '../models/Pagination';
import type { PatchPageActionPatch } from '../models/PatchPageActionPatch';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PageService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get pages
     * Return a list of pages for the current context.
     *
     * - Allowed roles: admin
     * @returns any Success
     * @throws ApiError
     */
    public listPageByDomainId({
        contextId,
        page = 1,
        perPage = 25,
        search,
        cache,
    }: {
        contextId: number,
        /**
         * Page number
         */
        page?: number,
        /**
         * Amount of items per page
         */
        perPage?: number,
        /**
         * Search on title
         */
        search?: string,
        cache?: boolean,
    }): CancelablePromise<{
        pagination?: Pagination;
        data?: Array<Page>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/page',
            path: {
                'contextID': contextId,
            },
            query: {
                'page': page,
                'perPage': perPage,
                'search': search,
                'cache': cache,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Create a page
     * Create a page and respond with the created page.
     *
     * - Allowed roles: admin
     * @returns Page Created
     * @throws ApiError
     */
    public createPageByDomainId({
        contextId,
        requestBody,
    }: {
        contextId: number,
        requestBody: PageActionCreate,
    }): CancelablePromise<Page> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/domain/{contextID}/page',
            path: {
                'contextID': contextId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Get page by slug & contextID
     * Return entities for a specific page
     * @returns Page Success
     * @throws ApiError
     */
    public getPageByDomainId({
        contextId,
        slug,
        cache,
    }: {
        contextId: number,
        /**
         * Get page by slug
         */
        slug: string,
        cache?: boolean,
    }): CancelablePromise<Page> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/page/{slug}',
            path: {
                'contextID': contextId,
                'slug': slug,
            },
            query: {
                'cache': cache,
            },
        });
    }
    /**
     * Update a page
     * Patch a page and respond with the patched page.
     *
     * - Allowed roles: admin
     * @returns Page Success
     * @throws ApiError
     */
    public patchPageByDomainId({
        contextId,
        id,
        requestBody,
    }: {
        contextId: number,
        id: number,
        requestBody: PatchPageActionPatch,
    }): CancelablePromise<Page> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/domain/{contextID}/page/{id}',
            path: {
                'contextID': contextId,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Deletes a page
     * Deletes and returns 204
     *
     * - Allowed roles: admin
     * @returns void
     * @throws ApiError
     */
    public deletePageByDomainId({
        contextId,
        id,
    }: {
        contextId: number,
        /**
         * Get page by id
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/domain/{contextID}/page/{id}',
            path: {
                'contextID': contextId,
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
}
