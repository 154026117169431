/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Bookmaker } from '../models/Bookmaker';
import type { BookmakerActionPatch } from '../models/BookmakerActionPatch';
import type { BookmakerBase } from '../models/BookmakerBase';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class BookmakerService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create bookmaker.
     * Create bookmaker.
     * @returns Bookmaker Created
     * @throws ApiError
     */
    public createBookmaker({
        requestBody,
    }: {
        requestBody: BookmakerBase,
    }): CancelablePromise<Bookmaker> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/bookmaker',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Get all bookmakers.
     * Get all bookmakers.
     *
     * - Allowed roles: editor, admin
     * @returns Bookmaker Success
     * @throws ApiError
     */
    public getAllBookmaker(): CancelablePromise<Array<Bookmaker>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/bookmaker',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Update bookmaker.
     * Update bookmaker.
     * @returns Bookmaker Success
     * @throws ApiError
     */
    public patchBookmaker({
        id,
        requestBody,
    }: {
        /**
         * will be used to update a specific bookmaker
         */
        id: number,
        /**
         * will be used to update a specific bookmaker
         */
        requestBody: BookmakerActionPatch,
    }): CancelablePromise<Bookmaker> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/bookmaker/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Delete bookmaker.
     * Delete bookmaker.
     * @returns void
     * @throws ApiError
     */
    public deleteBookmaker({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/bookmaker/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Get bookmaker.
     * Get bookmaker.
     *
     * - Allowed roles: editor, admin
     * @returns Bookmaker Success
     * @throws ApiError
     */
    public getByIdBookmaker({
        id,
    }: {
        id: number,
    }): CancelablePromise<Bookmaker> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/bookmaker/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
}
