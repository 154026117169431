/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Pagination } from '../models/Pagination';
import type { Shirt } from '../models/Shirt';
import type { Team } from '../models/Team';
import type { TeamSelection } from '../models/TeamSelection';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class TeamService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get all shirts
     * Get all shirts with teamname
     * @returns any Success
     * @throws ApiError
     */
    public getShirtsTeam(): CancelablePromise<{
        pagination?: Pagination;
        data?: Array<Shirt>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/football/team/shirt',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Get team selection
     * Get team selection
     * @returns TeamSelection Success
     * @throws ApiError
     */
    public getTeamSelectionTeam({
        id,
    }: {
        /**
         * OPTA ID of the requested team
         */
        id: string,
    }): CancelablePromise<TeamSelection> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/football/team/{id}/selection',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Get team details
     * Get team details
     * @returns Team Success
     * @throws ApiError
     */
    public getByIdTeam({
        id,
    }: {
        /**
         * OPTA ID of the requested team
         */
        id: string,
    }): CancelablePromise<Team> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/football/team/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Get team export for the magazine
     * Get team export for the magazine
     * @returns any Success
     * @throws ApiError
     */
    public getTeamExportTeam({
        id,
        playerId,
    }: {
        /**
         * OPTA ID of the requested team
         */
        id: string,
        /**
         * OPTA ID of the requested player
         */
        playerId?: string,
    }): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/football/team/{id}/export',
            path: {
                'id': id,
            },
            query: {
                'playerID': playerId,
            },
        });
    }
}
