import { logger } from '@common/logger';

export class EventHandler {
    static cache: any = {};

    static trigger(topic: string, ...args: Array<any>) {
        logger.debug('EventHandler.trigger', { topic, args });
        if (EventHandler.cache[topic]) {
            const thisTopic: Array<any> = EventHandler.cache[topic];
            thisTopic.map((boundObject: any) => {
                if (boundObject.scope === null || boundObject.scope === undefined) {
                    logger.debug('EventHandler.trigger scope == null', boundObject);
                    boundObject.callback.apply(topic, args);
                } else {
                    const scope = args[0];
                    logger.debug('EventHandler.trigger scope', { boundObject, scope });
                    if (boundObject.scope === scope) {
                        boundObject.callback.apply(topic, args);
                    }
                }
            });
        }
    }

    static bind(topic: string, scope: any = null, callback: any = null) {
        if (!EventHandler.cache[topic]) EventHandler.cache[topic] = [];

        if (callback === null || callback === undefined) {
            callback = scope;
            scope = null;
        }

        EventHandler.cache[topic].unshift({ scope: scope, callback: callback });
        return [topic, scope, callback];
    }

    static unbind(topic: string, destroy: Boolean = false) {
        const thisTopic = EventHandler.cache[topic];

        if (thisTopic) {
            if (destroy) {
                delete EventHandler.cache[topic];
            }
        }
    }
}
