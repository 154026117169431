/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuditLog } from '../models/AuditLog';
import type { Pagination } from '../models/Pagination';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AuditLogService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get audit logs
     * Return a list of audit logs for a contextID.
     *
     * - Allowed roles: admin
     * @returns any Success
     * @throws ApiError
     */
    public listAuditLogByDomainId({
        contextId,
        page = 1,
        perPage = 20,
        userId,
        affectedUserId,
        cache,
    }: {
        contextId: number,
        /**
         * Current page
         */
        page?: number,
        /**
         * Amount of items per page
         */
        perPage?: number,
        /**
         * Filter logs by the ID of a specific administrator
         */
        userId?: number,
        /**
         * Filter logs by the ID of the user subjected to the action
         */
        affectedUserId?: number,
        cache?: boolean,
    }): CancelablePromise<{
        pagination?: Pagination;
        data?: Array<AuditLog>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/audit-log',
            path: {
                'contextID': contextId,
            },
            query: {
                'page': page,
                'perPage': perPage,
                'userID': userId,
                'affectedUserID': affectedUserId,
                'cache': cache,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
}
