/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TestEventRequest } from '../models/TestEventRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class TestEventControllerService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Publish event
     * Publish event to Redis to simulate live events. Endpoint should be used only for testing purposes
     *
     * - Allowed roles: moderator, editor, admin
     * @returns boolean Success
     * @throws ApiError
     */
    public createTestEvent({
        requestBody,
    }: {
        requestBody: TestEventRequest,
    }): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/test/publishEvent',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
}
