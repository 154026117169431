/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AIPrompt } from '../models/AIPrompt';
import type { AiPromptActionUpdate } from '../models/AiPromptActionUpdate';
import type { AiPromptBaseActionCreate } from '../models/AiPromptBaseActionCreate';
import type { AIPromptModel } from '../models/AIPromptModel';
import type { AIPromptType } from '../models/AIPromptType';
import type { Pagination } from '../models/Pagination';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AiPromptService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get all aiPrompts
     * - Allowed roles: moderator, editor, admin
     * @returns any Success
     * @throws ApiError
     */
    public listAiPrompt({
        type,
        model,
        page = 1,
        perPage = 25,
        cache,
    }: {
        type?: AIPromptType,
        model?: AIPromptModel,
        /**
         * current page
         */
        page?: number,
        /**
         * amount of items per page
         */
        perPage?: number,
        cache?: boolean,
    }): CancelablePromise<{
        pagination?: Pagination;
        data?: Array<AIPrompt>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/football/ai-prompts',
            query: {
                'type': type,
                'model': model,
                'page': page,
                'perPage': perPage,
                'cache': cache,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Create an ai prompt
     * - Allowed roles: moderator, editor, admin
     * @returns AIPrompt Created
     * @throws ApiError
     */
    public createAiPrompt({
        requestBody,
    }: {
        /**
         * will be used to create an AI Prompt
         */
        requestBody: AiPromptBaseActionCreate,
    }): CancelablePromise<AIPrompt> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/football/ai-prompts/prompt',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Update an ai prompt
     * - Allowed roles: moderator, editor, admin
     * @returns AIPrompt Success
     * @throws ApiError
     */
    public updateAiPrompt({
        id,
        requestBody,
    }: {
        /**
         * will be used to update a specific aiPrompt
         */
        id: number,
        /**
         * will be used to update a specific aiPrompt
         */
        requestBody: AiPromptActionUpdate,
    }): CancelablePromise<AIPrompt> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/football/ai-prompts/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Delete aiPrompt.
     * - Allowed roles: moderator, editor, admin
     * @returns void
     * @throws ApiError
     */
    public deleteAiPrompt({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/football/ai-prompts/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
}
