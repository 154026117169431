/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BlockedDomain } from '../models/BlockedDomain';
import type { BlockedDomainActionCreate } from '../models/BlockedDomainActionCreate';
import type { Pagination } from '../models/Pagination';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class BlockedDomainService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get all blocked domains
     * Return a list of blocked domains
     *
     * - Allowed roles: editor, admin
     * @returns any Success
     * @throws ApiError
     */
    public listBlockedDomain({
        search,
        page = 1,
        perPage = 20,
    }: {
        /**
         * Search for a specific page
         */
        search?: string,
        /**
         * Current page
         */
        page?: number,
        /**
         * Amount of items per page
         */
        perPage?: number,
    }): CancelablePromise<{
        pagination?: Pagination;
        data?: Array<BlockedDomain>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/blocked-domain',
            query: {
                'search': search,
                'page': page,
                'perPage': perPage,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Add new blocked domain
     * Create a new blocked domain
     *
     * - Allowed roles: editor, admin
     * @returns BlockedDomain Created
     * @throws ApiError
     */
    public createBlockedDomain({
        requestBody,
    }: {
        requestBody: BlockedDomainActionCreate,
    }): CancelablePromise<BlockedDomain> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/blocked-domain',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Check if domain is blocked
     * @returns any Success
     * @throws ApiError
     */
    public checkBlockedDomain({
        email,
    }: {
        email: string,
    }): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/blocked-domain/check',
            query: {
                'email': email,
            },
        });
    }
    /**
     * Delete a blocked domain
     * - Allowed roles: editor, admin
     * @returns void
     * @throws ApiError
     */
    public deleteBlockedDomain({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/blocked-domain/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
}
