/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AIResult } from '../models/AIResult';
import type { MatchReport } from '../models/MatchReport';
import type { Pagination } from '../models/Pagination';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AiService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get all aiResults
     * - Allowed roles: editor, admin
     * @returns any Success
     * @throws ApiError
     */
    public listResultsAi({
        page = 1,
        perPage = 25,
        aiPromptId,
        cache,
    }: {
        /**
         * current page
         */
        page?: number,
        /**
         * amount of items per page
         */
        perPage?: number,
        /**
         * filter by aiPromptID
         */
        aiPromptId?: number,
        cache?: boolean,
    }): CancelablePromise<{
        pagination?: Pagination;
        data?: Array<AIResult>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/football/ai/result',
            query: {
                'page': page,
                'perPage': perPage,
                'aiPromptID': aiPromptId,
                'cache': cache,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Get the match reports
     * - Allowed roles: editor, admin
     * @returns any Success
     * @throws ApiError
     */
    public matchReportsAi({
        page = 1,
        perPage = 25,
        cache,
    }: {
        /**
         * current page
         */
        page?: number,
        /**
         * amount of items per page
         */
        perPage?: number,
        cache?: boolean,
    }): CancelablePromise<{
        pagination?: Pagination;
        data?: Array<MatchReport>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/football/ai/match-reports',
            query: {
                'page': page,
                'perPage': perPage,
                'cache': cache,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Get the latest generated match reports
     * @returns any Success
     * @throws ApiError
     */
    public latestMatchReportsAi({
        page = 1,
        perPage = 25,
    }: {
        /**
         * current page
         */
        page?: number,
        /**
         * amount of items per page
         */
        perPage?: number,
    }): CancelablePromise<{
        pagination?: Pagination;
        data?: Array<MatchReport>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/football/ai/latest-match-reports',
            query: {
                'page': page,
                'perPage': perPage,
            },
        });
    }
    /**
     * Generate match report for matchID and promptID
     * - Allowed roles: editor, admin
     * @returns MatchReport Success
     * @throws ApiError
     */
    public generateMatchReportAi({
        matchId,
        aiPromptId,
    }: {
        /**
         * optaID of the match to generate the report for
         */
        matchId?: string,
        /**
         * aiPromptID of the prompt to use
         */
        aiPromptId?: number,
    }): CancelablePromise<MatchReport> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/football/ai/generate-match-report',
            query: {
                'matchID': matchId,
                'aiPromptID': aiPromptId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Import / generate match reports from openAI
     * Import / generate match reports from openAI
     *
     * - Allowed roles: app_cron
     * @returns any Success
     * @throws ApiError
     */
    public generateMatchReportsAi(): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/football/ai/import/reports',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
}
