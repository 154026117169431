/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ForbiddenWord } from '../models/ForbiddenWord';
import type { ForbiddenWordActionCreate } from '../models/ForbiddenWordActionCreate';
import type { ForbiddenWordActionPatch } from '../models/ForbiddenWordActionPatch';
import type { Pagination } from '../models/Pagination';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ForbiddenWordService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get all forbidden words
     * - Allowed roles: moderator, editor, admin
     * @returns any Success
     * @throws ApiError
     */
    public listForbiddenWordByDomainId({
        contextId,
        search,
        page = 1,
        perPage = 25,
        cache,
    }: {
        contextId: number,
        /**
         * Search for forbidden words that contain the given search term.
         */
        search?: string,
        page?: number,
        perPage?: number,
        cache?: boolean,
    }): CancelablePromise<{
        pagination?: Pagination;
        data?: Array<ForbiddenWord>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/forbidden-word',
            path: {
                'contextID': contextId,
            },
            query: {
                'search': search,
                'page': page,
                'perPage': perPage,
                'cache': cache,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Create a forbidden word
     * - Allowed roles: moderator, editor, admin
     * @returns ForbiddenWord Created
     * @throws ApiError
     */
    public createForbiddenWordByDomainId({
        contextId,
        requestBody,
    }: {
        contextId: number,
        requestBody: ForbiddenWordActionCreate,
    }): CancelablePromise<ForbiddenWord> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/domain/{contextID}/forbidden-word',
            path: {
                'contextID': contextId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Get a forbidden word
     * Returns a single forbidden word
     * @returns ForbiddenWord Success
     * @throws ApiError
     */
    public getForbiddenWordByDomainId({
        contextId,
        id,
        cache,
    }: {
        contextId: number,
        /**
         * Get forbidden word by id
         */
        id: number,
        cache?: boolean,
    }): CancelablePromise<ForbiddenWord> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/forbidden-word/{id}',
            path: {
                'contextID': contextId,
                'id': id,
            },
            query: {
                'cache': cache,
            },
        });
    }
    /**
     * Update a forbidden word
     * - Allowed roles: moderator, editor, admin
     * @returns ForbiddenWord Success
     * @throws ApiError
     */
    public updateForbiddenWordByDomainId({
        contextId,
        id,
        requestBody,
    }: {
        contextId: number,
        id: number,
        requestBody: ForbiddenWordActionPatch,
    }): CancelablePromise<ForbiddenWord> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/domain/{contextID}/forbidden-word/{id}',
            path: {
                'contextID': contextId,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Delete a forbidden word
     * - Allowed roles: moderator, editor, admin
     * @returns void
     * @throws ApiError
     */
    public deleteForbiddenWordByDomainId({
        contextId,
        id,
    }: {
        contextId: number,
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/domain/{contextID}/forbidden-word/{id}',
            path: {
                'contextID': contextId,
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
}
